<template>
	<div class="logobox">
		<!-- 电脑模式 -->
		<el-row :gutter="20" style="width: 1450px;margin: 0 auto;" class="hidden-xs-only">
			<el-col :span="19" :xs="24">
				<div style="margin-left: 20px;margin: 0px auto;">
					<img src="../assets/logo.png" alt="">
				</div>
			</el-col>
			<el-col :span="5">
				<div class="div1">
					<ul>
						<li class="li1"><el-icon size="30">
								<Phone />
							</el-icon>咨询热线:</li>
						<li class="li2">010-87554697</li>
					</ul>
				</div>
			</el-col>
		</el-row>
		<!-- 手机模式 -->
		<el-affix :offset="0">
		   <el-row :gutter="20" style="width: 100%;height: 50px; margin: 0 auto;background-color: white;"
		   	class="hidden-sm-and-up">
		   	<el-col :span="21">
		   		<div>
		   			<img src="../assets/logo.png" alt="" style="width: 210px;height: 50px;">
		   		</div>
		   	</el-col>
		   	<el-col :span="3">
		   		<div>
		   			<el-icon size="20" color="#2eaafe" style=" margin: 15px auto;" @click="drawer = true">
		   				<Operation />
		   			</el-icon>
		   			<el-drawer v-model="drawer" :size="350">
		   				<p class="phonep1" @click="$router.push(`/`),drawer = false">公司首页</p>
		   				<p class="phonep1" @click="$router.push(`/gsjj`),drawer = false">公司简介</p>
		   				<p class="phonep1" @click="$router.push(`/news`),drawer = false">新闻中心</p>
		   				<p class="phonep1" @click="$router.push(`/news`),drawer = false">产品展示</p>
		   				<p class="phonep1" @click="$router.push(`/product`),drawer = false">布样展示</p>
		   				<p class="phonep1" @click="$router.push(`/trszcd`),drawer = false">天然山棕床垫</p>
		   				<p class="phonep1" @click="$router.push(`/hmjjwx`),drawer = false">红木家具维修</p>
		   				<p class="phonep1" @click="$router.push(`/zdlf`),drawer = false">坐垫量法</p>
		   				<p class="phonep1" @click="$router.push(`/lxwm`),drawer = false">联系我们</p>
		   			</el-drawer>
		   		</div>
		   	</el-col>
		   </el-row>
		  </el-affix>
		
	</div>
	<!-- 轮播图 -->
	<div class="block text-center hidden-sm-and-up">
		<el-carousel height="270px">
			<el-carousel-item v-for="itme in gggllist" :key="_id">
				<img style="width: 100%;height: 100%;" :src="proxy.$key + itme.avatar"/>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script setup>
	import {Phone,Operation} from '@element-plus/icons-vue'
	import 'element-plus/theme-chalk/display.css'
	import {useRouter} from 'vue-router'
	import {onMounted,ref} from "vue";
	import axios from 'axios';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const drawer = ref(false)
	const gggllist = ref([])
	onMounted(async()=>{
		// 首页轮播图
		var res1 = await axios.get(`/webapi/gggl/list2?limit=4`)
		gggllist.value = res1.data.data
	})
</script>

<style lang="scss" scoped>
	.logobox {
		
	}

	.phonep1 {
		text-align: center;
		margin: 20px 10px;
		border-bottom: 1px solid #f0efef;
		padding-bottom: 20px;
	}

	.el-row {
		height: 98px;
	}

	.div1 {
		margin-top: 20px;
	}

	.li1 {
		font-size: 14px;
	}

	.li2 {
		font-size: 26px;
		color: #1ea4fe;
	}
</style>