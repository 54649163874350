<template>
	<el-row></el-row>
	<div class="common-layout">
		<!-- 电脑模式 -->
		<el-container class="hidden-xs-only">
			<el-main>
				<div class="box">
					<el-row :gutter="20">
						<el-col :span="24">
							<p style="letter-spacing:3px;text-align: center;position: relative;top:60px;font-weight: normal;font-size: 30px;color: #333333;">{{zdlflist.zdlfname}}</p>
							<div class="ss">
								<div class="imgbox">
									<img :src="proxy.$key + zdlflist.avatar">
								</div>
							</div>
						</el-col>
					</el-row>
					<div class="fyq">
						<el-pagination background layout="prev, pager, next,jumper,total" prev-text="上一页" next-text="下一页" :total="data.total" :page-size="data.perPage"
							:current-page="data.page" @update:current-page="data.page = $event" @current-change="getTableData" />
					</div>
				</div>
			</el-main>
		</el-container>
	</div>
	<!-- 手机模式 -->
	<el-row class="hidden-sm-and-up">
		<el-col :span="24">
			<div class="phonebox">
				<p class="p3">{{zdlflist.zdlfname}}</p>
				<span style="margin-left: 10px;font-size: 10px;">{{zdlflist.zdlfnumber}}</span>
				<div class="phonebox2">
					<el-row :gutter="10">
						<el-col :span="24">

							<div style="width: 100%;height: 340px;max-width: 420px;margin: 10px auto;">
								<img :src="proxy.$key + zdlflist.avatar" style="width: 100%; height: 280px;border-radius: 8px;">
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</el-col>
		<div style="margin-bottom: 30px;width: 95%;">
			<el-pagination background layout="prev,  next,jumper" prev-text="上一页" next-text="下一页" :total="data.total" :page-size="data.perPage"
				:current-page="data.page" @update:current-page="data.page = $event" @current-change="getTableData" />
		</div>
	</el-row>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref,reactive} from "vue";
	import {useRoute} from 'vue-router';
	import axios from 'axios';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const route = useRoute()
	const index = route.query.id
	// console.log(index);
	const zdlflist = ref([])
	// data响应数据
	const data = reactive({
		total: 0, //总条数
		page: Number(index), //第几页
		perPage: 1, //每页显示条数

	})
	onMounted(() => {
		getTableData();
	});
	const getTableData = async (index) => {
		// 获取列表数据
		const res = await axios.get("/webapi/zdlf/list", {
			params: data
		});
		// console.log(res.data)
		zdlflist.value = res.data.data[0];
		index = route.params.id
		// console.log(res.data);
		// 获取总页数
		data.total = res.data.total;
	};
</script>

<style lang="scss" scoped>
	.p3 {
		text-align: center;
		font-size: 22px;
		line-height: 100px;
		color: #646464;
	}

	.imgbox img {
		width: 800px;
		height: 560px;
	}

	.phonebox {
		background-color: white;
		display: block;
	}

	.phonebox2 {
		width: 95%;
		margin: 0 auto;
	}

	.common-layout {
		width: 1450px;
		margin: 0 auto;
	}

	.ss {
		width: 800px;
		height: 560px;
		background-color: #ffffff;
		border: 1px solid #d2d2d6;
		margin: 0px auto;
		cursor: pointer;
		margin-top: 60px;
	}

	.el-pagination {
		margin-top: 10px;
		justify-content: center;
	}
</style>