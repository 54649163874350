<template>
	<!-- 手机模式 -->
	<el-row class="hidden-sm-and-up">
		<el-col :span="24">
			<div class="phonebox">
				<p class="p3">布样展示</p>
			<el-row style="position: absolute;top: 35px;left: 65%">
			    <el-col :span="12"><el-button  type="primary" >丝绸</el-button></el-col>
			    <el-col :span="12"><el-button  style="margin-left: 5px;" @click="$router.push('/byzscx')">刺绣</el-button></el-col>
			  </el-row>
				<!-- <el-button type="primary" >丝绸面料</el-button>
				<el-button  style="" @click="$router.push('/byzscx')">刺绣面料</el-button> -->
				<div class="phonebox2">
					<el-row :gutter="10">
						<el-col :span="12" v-for="(itme,index) in byzssclist" :key="index" @click="handleChangepage(index)">
							<div style="width: 100%;height: 180px;max-width: 220px;margin: 10px auto;">
								<img :src="proxy.$key + itme.avatar"
									style="width: 100%; height: 140px;border-radius: 8px;">
								<p style="text-align: center;margin-top: 5px;color: #666;font-weight: bold;">
									{{itme.byzsscname}}</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</el-col>
		<div style="margin: 30px auto;width: 90%;">
			<el-pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" :total="data.total" :page-size="data.perPage" :pager-count="4"
				:current-page="data.page" @update:current-page="data.page = $event" @current-change="getTableData" />
		</div>
	</el-row>
	<div class="common-layout">
		<!-- 电脑模式 -->
		<el-container class="hidden-xs-only">
			<el-header style="border-bottom: solid 1px #d2d2d6;
		border-top: solid 1px #d2d2d6;height: 80px;">
				<p class="p1">布样展示 丝绸</p>
				<el-button type="primary" style="position: relative;top: -55px;left: 1100px;">丝绸面料</el-button>
				<el-button  style="position: relative;top: -55px;left: 1100px;" @click="$router.push('/byzscx')">刺绣面料</el-button>
			</el-header>
			<el-main>
				<div class="box" style="min-height: 400px;">
					<el-row :gutter="20">
						<el-col :span="6" v-for="(itme,index) in byzssclist" :key="index" @click="handleChangepage(index)">
							<div class="ss">
								<div class="imgbox"><img :src="proxy.$key + itme.avatar" /></div>
								<p class="p2">{{itme.byzsscname}}</p>
							</div>
						</el-col>
					</el-row>
					<div class="fyq">
						<el-pagination background layout="prev, pager, next,jumper,total" :total="data.total"
							:page-size="data.perPage" :current-page="data.page" prev-text="上一页" next-text="下一页"
							@update:current-page="data.page = $event" @current-change="getTableData" />
					</div>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref,reactive} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const byzssclist = ref([])
	// data响应数据
	const data = reactive({
		total: 0, //总条数
		page: 1, //第几页
		perPage: 12, //每页显示条数

	})
	onMounted(() => {
		getTableData();
	});
	const getTableData = async () => {
	
		const res = await axios.get("/webapi/byzssc/list", {
			params: data
		});
		byzssclist.value = res.data.data;
		data.total = res.data.total
	};
	
	const router = useRouter()
	const handleChangepage = (index)=>{
		const id = 12*(data.page-1)+index+1;
		router.push(`/byzssclist/${id}`)
		router.push({
			name:"byzssclist",query:{id:id} 
		})
	}
</script>

<style lang="scss" scoped>
	.p3 {
		text-align: center;
		font-size: 22px;
		line-height: 100px;
		color: #646464;
	}

	.phonebox {
		background-color: white;
		display: block;
		min-height: 260px;
	}

	.phonebox2 {
		width: 95%;
		margin: 0 auto;
	}

	.common-layout {
		width: 1450px;
		margin: 0 auto;
	}

	.p1 {
		font-size: 30px;
		padding: 20px 0;
	}

	.ss {
		width: 310px;
		height: 260px;
		background-color: #ffffff;
		border-bottom: 1px solid #d2d2d6;
		border-left: 1px solid #d2d2d6;
		border-right: 1px solid #d2d2d6;
		margin: 10px auto;
		cursor: pointer;
		margin-top: 40px;
	}

	.imgbox {
		width: 310px;
		height: 210px;
		overflow: hidden;
	}

	.ss img {
		width: 310px;
		height: 210px;
	}

	.ss:hover .p2 {
		color: #1ea4fe;
	}

	.ss:hover .imgbox img {
		transform: scale(1.2);
	}

	.imgbox img {
		transition: 0.8s;
	}

	.ss:hover {
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
	}

	.p2 {
		text-align: center;
		font-size: 20px;
		color: #666;
		margin-top: 10px;
	}

	.el-pagination {
		margin-top: 10px;
		justify-content: center;
	}
</style>