import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Gsjj from '../views/Gsjj.vue'
import News from '../views/News.vue'
import Newslist from '../views/viewslist/Newslist.vue'
import Product from '../views/Product.vue'
import Productlist from '../views/viewslist/Productlist.vue'
import Byzs from '../views/Byzs.vue'
import Byzscx from '../views/Byzscx.vue'
import Byzssclist from '../views/viewslist/Byzssclist.vue'
import Byzscxlist from '../views/viewslist/Byzscxlist.vue'
import Trszcd from '../views/Trszcd.vue'
import Trszcdlist from '../views/viewslist/Trszcdlist.vue'
import Zdlf from '../views/Zdlf.vue'
import Zdlflist from '../views/viewslist/Zdlflist.vue'
import Hmjjwx from '../views/Hmjjwx.vue'
import Hmjjwxlist from '../views/viewslist/Hmjjwxlist.vue'
import Lxwm from '../views/Lxwm.vue'


// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = [
	{
		path:'/',
		name:'home',
		component:Home,
		meta:{
			name:'active1',
		}
	},
	{
		path:'/gsjj',
		name:'gsjj',
		component:Gsjj,
		meta:{
			name:'active2'
		}
	},
	{
		path:'/news',
		name:'news',
		component:News,
		meta:{
			name:'active3'
		}
	},
	{
		path:'/product',
		name:'product',
		component:Product,
		meta:{
			name:'active4'
		}
	},
	{
		path:'/byzs',
		name:'byzs',
		component:Byzs,
		meta:{
			name:'active5'
		}
	},
	{
		path:'/byzscx',
		name:'byzscx',
		component:Byzscx,
		meta:{
			name:'active5'
		}
	},
	{
		path:'/trszcd',
		name:'trszcd',
		component:Trszcd,
		meta:{
			name:'active6'
		}
	},
	{
		path:'/zdlf',
		name:'zdlf',
		component:Zdlf,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/hmjjwx',
		name:'hmjjwx',
		component:Hmjjwx,
		meta:{
			name:'active7'
		}
	},
	{
		path:'/lxwm',
		name:'lxwm',
		component:Lxwm
	},
	{
		path:'/newslist/:id',
		name:'newslist',
		component:Newslist,
		meta:{
			name:'active3'
		}
	},
	{
		path:'/productlist',
		name:'productlist',
		component:Productlist,
		meta:{
			name:'active4'
		}
	},
	{
		path:'/zdlflist',
		name:'zdlflist',
		component:Zdlflist,
		meta:{
			name:'active8'
		}
	},
	{
		path:'/hmjjwxlist/:id',
		name:'hmjjwxlist',
		component:Hmjjwxlist,
		meta:{
			name:'active7'
		}
	},
	{
		path:'/trszcdlist',
		name:'trszcdlist',
		component:Trszcdlist,
		meta:{
			name:'active6'
		}
	},
	{
		path:'/byzssclist',
		name:'byzssclist',
		component:Byzssclist,
		meta:{
			name:'active5'
		}
	},
	{
		path:'/byzscxlist',
		name:'byzscxlist',
		component:Byzscxlist,
		meta:{
			name:'active5'
		}
	}
]

const router = createRouter({
   history: createWebHistory(),
  // mode:"history",
  routes
})

 // 路由跳转前的钩子，进度条加载
router.beforeEach((to,from,next)=>{
	NProgress.start();
	next()
})
// 路由跳转后的钩子，进度条关闭
router.afterEach((to,from,next)=>{
	NProgress.done();
}) 
export default router
