<template>
	<!-- 电脑模式 -->
	<el-row class="hidden-xs-only">
		<div class="common-layout" style="min-height: 300px;">
			<el-container>
				<el-header style="border-bottom: solid 1px #d2d2d6;
		border-top: solid 1px #d2d2d6;height: 80px;">
					<p class="p1">新闻中心</p>
				</el-header>
				<el-main>
					<ul style="cursor: pointer;">
						<li v-for="itme in newlist" :key="_id" @click="handleChangepage(itme._id)">
							<div class="libox">
								<img :src="proxy.$key + itme.avatar" />
							</div>
							<div class="libox2">
								<h4>{{itme.newsname}}</h4>
								<p>&nbsp;&nbsp;&nbsp;{{itme.newstxt}}</p>
							</div>
						</li>
						
					</ul>
				</el-main>
			</el-container>
		</div>
	</el-row>
	<!-- 手机模式 -->
	<el-row class="hidden-sm-and-up" style="min-height: 260px;">
		<el-col :span="24" style="margin-top: 10px;" v-for="itme in newlist" :key="_id" @click="handleChangepage(itme._id)">
			<div style="width: 100%;height: 150px;margin-bottom: 10px; position: relative">
					<div style="position: absolute;top: 25px;left: 10px;width: 95%;display: flex;border-bottom: solid 1px #d2d2d6;padding-bottom: 22px;">
						<img :src="proxy.$key + itme.avatar" style="width: 135px;height: 100px;">
						<div style="margin-left: 10px;width:100%;">
							<span style="font-size: 18px;color: #333333;">{{itme.newsname}}</span>
							<p style="font-size: 15px;color: #999999;max-height: 80px; overflow: hidden;">&nbsp;&nbsp;&nbsp;{{itme.newstxt}}</p>
						</div>
					</div>
			</div>
		</el-col>
	</el-row>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const newlist = ref([])
	onMounted(async()=>{
		var res = await axios.get("/webapi/news/list")
		newlist.value = res.data.data
		// console.log(res.data.data)
	})
	
	const router = useRouter()
	const handleChangepage = (id)=>{
		router.push(`/newslist/${id}`)
		console.log(id);
	}
</script>

<style lang="scss" scoped>
	.common-layout {
		width: 1450px;
		margin: 0 auto;
	}

	.p1 {
		font-size: 30px;
		padding-top: 20px;
	}

	ul li {
		height: 190px;
		margin-top: 15px;
		display: flex;
		border-bottom: solid 2px #d2d2d6;
		padding-bottom: 40px;
		padding-bottom: 20px;
		min-height: 200px;
	}

	img {
		width: 253px;
		height: 190px;
	}

	.libox {
		width: 253px;
		height: 190px;
	}

	.libox2 {
		height: 190px;
		width: 1157px;
	}

	.libox2 h4 {
		margin: 20px 20px;
	}

	.libox2 p {
		margin: -10px 20px;
		color: #666;
		font-size: 14px;
	}
</style>