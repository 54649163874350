<template>
	<el-col class="hidden-xs-only">
		<div class="lbt">
			<ul class="list">
				<li class="item" v-for="itme in gggllist" :key="_id"><img :src="proxy.$key + itme.avatar" />></li>
				<li class="item" v-for="itme in gggllist" :key="_id"><img :src="proxy.$key + itme.avatar" />></li>
			</ul>
		</div>
	</el-col>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const gggllist = ref([])
	onMounted(async()=>{
		// 首页轮播图
		var res1 = await axios.get("/webapi/gggl/list")
		gggllist.value = res1.data.data
		
	})
</script>

<style lang="scss" scoped>
	/* 轮播图模块 */
	.lbt {
		width: 1450px;
		height: 180px;
		margin: 10px auto;
		overflow: hidden;
	}
	.lbt ul {
		display: flex;
	}

	.item img {
		width: 100%;
		height: 100%;
	}

	.list li {
		width: 260px;
		height: 180px;
		flex-shrink: 0;
		box-sizing: border-box;
		margin: 0 2px;
	}

	.lbt ul {
		display: flex;
		animation: scroll 40s linear infinite;
	}

	@keyframes scroll {
		0% {
			transform: translate(0px, 0px);
		}

		100% {
			transform: translate(-3168px, 0px);
		}
	}
</style>