<template>
	<el-row class="hidden-xs-only">
		<div class="common-layout">
			<el-container>
				<!-- 主体左侧 -->
				<el-aside width="250px">
					<!-- 	主体左侧模块 -->
					<div class="mianl">
						<div class="mianlimg">
							<img :src="proxy.$key + gssylist.avatar">
						</div>
						<ul class="mianllist">
							<li @click="$router.push('/byzs')">丝绸布样</li>
							<li @click="$router.push('/byzscx')">刺绣布样</li>
							<li @click="$router.push('/trszcd')">天然山棕床垫</li>
						</ul>
					</div>
				</el-aside>
				<el-main>
					<!-- 主体中部模块 -->
					<div style="margin-top: -40px;"><br>

						<h3 class="gsjj">公司简介:</h3>
						<hr>
						<p class="miantxt" v-html="gsjjlist.content" @click="$router.push('/gsjj')"></p>
					</div>
				</el-main>
				<!-- 右侧 -->
				<el-aside width="250px">
					<!-- 主体右侧模块 -->
					<div class="mianr">
						<div class="mianlimg">
							<img :src="proxy.$key + gssylist2.avatar" alt="">
						</div>
						<ul class="mianllist">
							<li @click="$router.push('/hmjjwx')">红木家具维修</li>
						</ul>
					</div>
				</el-aside>
			</el-container>
			<!-- 产品展示轮播图 -->
			<div class="cpzsbannner">
				<h3 class="cpzstxt">产品展示：</h3>
				<div class="lbt">
					<ul class="list">
						<li class="item" v-for="itme in gggllist" :key="_id"><img :src="proxy.$key + itme.avatar" />></li>
						<li class="item" v-for="itme in gggllist" :key="_id"><img :src="proxy.$key + itme.avatar" />></li>
					</ul>
				</div>
			</div>
		</div>
	</el-row>
	<!-- 手机版 -->
	<el-row>
		<el-col :span="24" class="hidden-sm-and-up">
			<div class="phonebox">
				<p class="p1">产品展示</p>
				<div style="width: 100px; margin: 0px auto;margin-bottom: 20px;"><el-button
						type="primary" @click="$router.push('/product')">全部产品</el-button></div>
				<div class="phonebox2">
					<el-row :gutter="10">
						<el-col :span="12" v-for="(itme,index) in productlist" :key="index"
							@click="handleChangepage(index)">
							<div style="width: 100%;height: 180px;max-width: 220px;margin: 10px auto;">
								<img :src="proxy.$key + itme.avatar"
									style="width: 100%; height: 140px;border-radius: 8px;">
								<p style="text-align: center;margin-top: 5px;color: #666;font-weight: bold;">
									{{itme.productname}}</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="phonebox" style="background-color: #f4f5f6;">
				<p class="p1">公司简介</p>
				<div style="width: 100px; margin: 0px auto;margin-bottom: 20px;"><el-button
						type="primary" @click="$router.push('/gsjj')">查看详情</el-button></div>
				<div class="phonebox2">
					<el-row :gutter="10">
						<el-col :span="24">
							<div style="width: 90%;margin: 10px auto;">
								<p style="color: #666;margin-bottom: 100px;line-height: 25px; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 15;-webkit-box-orient: vertical;"
									v-html="gsjjlist.content"></p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script setup>
	import 'element-plus/theme-chalk/display.css'
	import {onMounted,ref,reactive} from "vue";
	import axios from 'axios';
	import {useRouter} from 'vue-router';
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const productlist = ref([])
	const gssylist = ref([])
	const gssylist2 = ref([])
	const gsjjlist = ref([])
	const gggllist = ref([])
	const data = reactive({
		total: 0, //总条数
		page: 1, //第几页
		perPage: 12, //每页显示条数

	})
	onMounted(async () => {
		// 手机端产品展示
		const res2 = await axios.get("/webapi/product/list", {params: data})
		productlist.value = res2.data.data;
		// 首页图片
		var res3 = await axios.get("/webapi/gssy/list")
		gssylist.value = res3.data.data[0]
		gssylist2.value = res3.data.data[1]
		// 首页文字
		var res = await axios.get("/webapi/gsjj/list")
		gsjjlist.value = res.data.data[0]
		// 首页产品展示轮播图
		var res1 = await axios.get("/webapi/ggglpt/list")
		gggllist.value = res1.data.data
	})



	const router = useRouter()
	const handleChangepage = (index) => {
		const id = 12 * (data.page - 1) + index + 1;
		router.push(`/productlist/${id}`)
		router.push({
			name: "productlist",
			query: {
				id: id
			}
		})
	}
</script>

<style lang="scss" scoped>
	.p1 {
		text-align: center;
		font-size: 22px;
		line-height: 100px;
		color: #646464;
	}

	.phonebox {
		background-color: white;
		display: block;
	}

	.phonebox2 {
		width: 95%;
		margin: 0 auto;
	}

	.common-layout {
		background-color: #f4f5f6;
		width: 1450px;
		margin: 0px auto;
	}

	.mianllist a {
		letter-spacing: 2px;
		line-height: 30px;
	}

	.mianllist {
		margin-left: 40px;
	}
	.mianllist li{
		cursor: pointer;
		margin-top: 20px;
	}
	.mianlimg img {
		width: 210px;
		height: 118px;
		// margin: 65px 20px;
		margin-top: 65px;
		margin-left: 20px;
	}

	/* 公司简介 */
	.gsjj {
		margin: 20px 20px;
	}

	.miantxt {
		line-height: 40px;
		font-size: 16px;
		cursor: pointer;
		// max-height: 320px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 10 ;
		-webkit-box-orient: vertical;
	}

	.cpzstxt {
		padding: 8px;
	}

	.cpzsbannner {
		margin-left: 280px;
	}

	/* 轮播图模块 */
	.lbt {
		width: 1164px;
		height: 105px;
		margin: 10px auto;
		overflow: hidden;
	}

	.lbt :hover {}

	.lbt ul {
		display: flex;
	}

	.item img {
		width: 100%;
		height: 100%;
	}

	.list li {
		width: 142px;
		height: 105px;
		flex-shrink: 0;
		box-sizing: border-box;
		margin: 0 2px;
	}

	.lbt ul {
		display: flex;
		animation: scroll 40s linear infinite;
	}

	@keyframes scroll {
		0% {
			transform: translate(0px, 0px);
		}

		100% {
			transform: translate(-2190px, 0px);
		}
	}
</style>